<template>
	<div>
		<section>
			<div class="margin-top-20" v-if="noteType === 'visitNote'">
				<b-field label="Select Visiting date">
					<b-datepicker
						v-model="selectedVisitingDate"
						:show-week-number="showWeekNumber"
						:locale="locale"
						:min-date="today"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
			</div>
			<div class="margin-top-20" v-if="noteType === 'alarmNote'">
				<b-field label="Select Alarm date">
					<b-datepicker
						v-model="selectedAlarmDate"
						:show-week-number="showWeekNumber"
						:locale="locale"
						:min-date="today"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
			</div>
		</section>
		<div style="margin-top: 35px">
			<form id="formVisit">
				<vue-form-generator
					@validated="onFormValidated"
					:schema="schemaVisit"
					:model="modelVisit"
					:options="formOptionsVisit"
				>
				</vue-form-generator>
			</form>
			<div
				style="
					padding-bottom: 30px;
					display: flex;
					justify-content: space-between;
				"
			>
				<div v-if="mutationType === 'add'">
					<b-button type="is-success" style="width: 250px" @click="onSubmit"
						>Save</b-button
					>
				</div>
				<div v-if="mutationType === 'edit'">
					<b-button type="is-info" style="width: 250px" @click="onUpdate"
						>Update</b-button
					>
				</div>
				<div>
					<b-button type="is-danger" style="width: 250px" @click="onDelete"
						>Delete</b-button
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	ref,
	reactive,
	computed,
	watchEffect,
	onMounted,
} from '@vue/composition-api'
import useCreateCustomerVisitState from '@/_srcv2/pages/business-development/customer-visits/scripts/useCreateCustomerVisitState.js'
import dateformat from 'dateformat'
import Store from '@/store'
import AddCustomersVisitMutation from '@/_srcv2/pages/business-development/customer-visits/graphql/AddCustomersVisitMutation.graphql'
import UpdateCustomersVisitsMutation from '@/_srcv2/pages/business-development/customer-visits/graphql/UpdateCustomersVisitsMutation.graphql'
import DeleteCustomerVisitOrAlarmMutation from '@/_srcv2/pages/business-development/customer-visits/graphql/DeleteCustomerVisitOrAlarmMutation.graphql'
import { useMutation } from '@vue/apollo-composable'

export default {
	name: 'CreateCustomerVisitSubComponent',
	setup() {
		const {
			modelVisit,
			schemaVisit,
			formOptionsVisit,
			noteType,
			mutationType,
			isFrameVisible,
			fireRefetch,
		} = useCreateCustomerVisitState()
		// todo ------------------------------------------------
		const parameters = reactive({
			visitDate: '',
			alarmDate: '',
		})
		const today = ref(new Date())
		const fetchDates = () => {
			return new Promise((resolve, reject) => {
				if (today.value !== null) {
					selectedVisitingDate.value = today.value
					selectedAlarmDate.value = today.value
					resolve('ok')
				} else {
					const reason = new Error('Today is null')
					reject(reason)
				}
			})
		}
		const getToday = () => {
			fetchDates()
		}
		const selectedVisitingDate = ref(new Date())
		watchEffect(() => {
			parameters.visitDate = dateformat(
				selectedVisitingDate.value,
				'yyyy-mm-dd',
			)
		})
		const selectedAlarmDate = ref(new Date())
		watchEffect(() => {
			parameters.alarmDate = dateformat(selectedAlarmDate.value, 'yyyy-mm-dd')
		})
		const showWeekNumber = false
		const locale = 'sv-SE'
		onMounted(() => getToday())
		const onFormValidated = () => {
			return 42
		}
		// todo ------------------------------------------------
		const onClose = () => {
			isFrameVisible.value = false
		}
		const mutationInput = computed(() => {
			if (noteType.value === 'visitNote' && mutationType.value === 'add') {
				return {
					company_id: Store.getters.getUserCurrentCompany,
					customer_id: modelVisit.customerID,
					visit_date: parameters.visitDate,
					visit_notes: modelVisit.visitNotes,
					is_customer_visited: false,
					address_id: modelVisit.addressID,
				}
			} else if (
				noteType.value === 'alarmNote' &&
				mutationType.value === 'add'
			) {
				return {
					company_id: Store.getters.getUserCurrentCompany,
					customer_id: modelVisit.customerID,
					visit_alarm_date: parameters.alarmDate,
					visit_alarm_notes: modelVisit.visitAlarmNotes,
					is_alarm_active: modelVisit.isAlarmActive,
					address_id: modelVisit.addressID,
				}
			}
		})
		// ? Add new customer visit or alarm
		const { mutate: addVisit, onDone } = useMutation(
			AddCustomersVisitMutation,
			() => ({
				variables: {
					input: mutationInput.value,
				},
			}),
		)
		const onSubmit = () => {
			console.log('mutationInput', mutationInput.value)
			addVisit()
		}
		onDone((res) => {
			console.log('onDone is fired')
			console.log('res', res)
			fireRefetch.value += 1
			onClose()
		})
		// todo ------------------------------------------------
		const updateMutationInput = computed(() => {
			if (noteType.value === 'visitNote' && mutationType.value === 'edit') {
				return {
					input: {
						visit_date: parameters.visitDate,
						visit_notes: modelVisit.visitNotes,
						is_customer_visited: modelVisit.isCustomerVisited,
					},
					visit_id: modelVisit.visitID,
					company_id: Store.getters.getUserCurrentCompany,
					customer_id: modelVisit.customerID,
				}
			} else if (
				noteType.value === 'alarmNote' &&
				mutationType.value === 'edit'
			) {
				return {
					input: {
						visit_alarm_date: parameters.alarmDate,
						visit_alarm_notes: modelVisit.visitAlarmNotes,
						is_alarm_active: modelVisit.isAlarmActive,
					},
					visit_id: modelVisit.visitID,
					company_id: Store.getters.getUserCurrentCompany,
					customer_id: modelVisit.customerID,
				}
			}
		})
		// ? Edit customer visit or alarm
		const { mutate: updateVisit, onDone: onDoneUpdate } = useMutation(
			UpdateCustomersVisitsMutation,
			() => ({
				variables: updateMutationInput.value,
			}),
		)
		const onUpdate = () => {
			console.log('updateMutationInput.value', updateMutationInput.value)
			updateVisit()
		}
		onDoneUpdate((res) => {
			console.log('onDoneUpdate is fired')
			console.log('res', res)
			fireRefetch.value += 1
			onClose()
		})
		// todo ------------------------------------------------
		const deleteMutationInput = computed(() => {
			return {
				visit_id: modelVisit.visitID,
				company_id: Store.getters.getUserCurrentCompany,
				customer_id: modelVisit.customerID,
			}
		})
		// ? Delete customer visit or alarm
		const { mutate: deleteVisitAlarm, onDone: onDoneDelete } = useMutation(
			DeleteCustomerVisitOrAlarmMutation,
			() => ({
				variables: deleteMutationInput.value,
			}),
		)
		const onDelete = () => {
			console.log('deleteMutationInput.value', deleteMutationInput.value)
			deleteVisitAlarm()
		}
		onDoneDelete((res) => {
			console.log('onDoneUpdate is fired')
			console.log('res', res)
			fireRefetch.value += 1
			onClose()
		})
		return {
			modelVisit,
			schemaVisit,
			formOptionsVisit,
			selectedVisitingDate,
			selectedAlarmDate,
			showWeekNumber,
			locale,
			noteType,
			mutationType,
			onFormValidated,
			today,
			onSubmit,
			onUpdate,
			onDelete,
		}
	},
}
</script>

<style lang="scss" scoped></style>
